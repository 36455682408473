<script setup lang="ts">
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'lucide-vue-next';
import { colors } from '../../config';
import { Direction } from '../../types/common';

withDefaults(defineProps<{
  buttonStyleDark?: boolean;
  floatingButtonColor?: string;
  hasFullHeightButtons?: boolean;
  hideArrows?: boolean;
  left: boolean;
  neverHideButtons?: boolean;
  right: boolean;
  scrollDistance?: number;
  scrollerWrapperWidth: number;
  stylingButtonLeft?: string;
  stylingButtonRight?: string;
} &({
  backgroundColor: string;
  fadeOutEdges: true;
} | {
  backgroundColor?: string;
  fadeOutEdges?: false;
})>(), { buttonStyleDark: false,floatingButtonColor: 'white',hasFullHeightButtons: false,hideArrows: false,neverHideButtons: false,scrollDistance: 200,stylingButtonLeft: 'left-5',stylingButtonRight: 'right-5', })

defineEmits<{
  scrollNow: [ distance: number ];
}>();

const buttonClasses = computed(() => ({
  '!flex': __props.neverHideButtons,
  'button-dark': __props.buttonStyleDark,
  'scroller-button-full-height': __props.hasFullHeightButtons,
}));

const buttonConfig = computed(() => {
  const buttons = [
    {
      class: [
        `scroller-button-${Direction.LEFT}`,
        `scroller-button-${Direction.LEFT}-${__props.floatingButtonColor}`,
        __props.stylingButtonLeft,
        buttonClasses.value,
      ],
      direction: Direction.LEFT,
      isActive: !__props.left,
      scrollDistance: __props.scrollDistance
        ? -__props.scrollDistance
        : -__props.scrollerWrapperWidth,
    },
    {
      class: [
        `scroller-button-${Direction.RIGHT}`,
        `scroller-button-${Direction.RIGHT}-${__props.floatingButtonColor}`,
        __props.stylingButtonRight,
        buttonClasses.value,
      ],
      direction: Direction.RIGHT,
      isActive: !__props.right,
      scrollDistance: __props.scrollDistance || __props.scrollerWrapperWidth,
    },
  ];

  return buttons.filter(({ isActive }) => isActive);
});

const floatingButtonStyle = (buttonDirection: Direction) => (__props.floatingButtonColor?.startsWith('#')
  ? {
    background: `linear-gradient(to ${buttonDirection === Direction.LEFT
      ? 'right'
      : 'left'}, ${__props.floatingButtonColor}, transparent)`,
    color: isDarkColor(__props.floatingButtonColor)
      ? colors.white
      : colors.dark,
  }
  : {});
</script>

<template>
  <div
    v-if="fadeOutEdges"
    class="fade-out-left absolute left-0 top-0 z-40 h-full w-8"
    :style="{ backgroundImage: `linear-gradient(90deg, ${backgroundColor}, transparent)` }"
  ></div>
  <div
    v-if="fadeOutEdges"
    class="fade-out-right absolute right-0 top-0 z-40 h-full w-8"
    :style="{ backgroundImage: `linear-gradient(-90deg, ${backgroundColor}, transparent)` }"
  ></div>
  <UiFloatingButton
    v-for="button in buttonConfig"
    :key="button.direction"
    class="scroller-button"
    :class="cn(
      'absolute top-[calc(50%-20px)] size-10 z-100 z-50 group-hover/scroller:flex', button.class,
      hideArrows ? 'w-4 px-0' : 'px-1.5'
    )"
    :dark="buttonStyleDark"
    :hide-icon="hideArrows"
    :icon="button.direction === Direction.LEFT ? ArrowLeftIcon : ArrowRightIcon"
    :style="floatingButtonStyle(button.direction)"
    @click.stop="$emit('scrollNow', button.scrollDistance)"
  />
</template>

<style scoped>
@reference '@/styles/global.css';

.scroller-button-full-height {
  @apply top-0 h-full rounded-none;
}

.scroller-button-left.scroller-button-full-height {
  @apply left-0;
}

.scroller-button-full-height :deep(.floating-button-content::after) {
  @apply !hidden;
}

.scroller-button-left.scroller-button-full-height :deep(.floating-button-content) {
  @apply justify-start;
}

.scroller-button-left-white.scroller-button-full-height {
  @apply bg-gradient-to-r from-white via-white to-white/0;
}

.scroller-button-left-purple-light.scroller-button-full-height {
  @apply bg-gradient-to-r from-purple-light via-purple-light to-purple-light/0;
}

.scroller-button-right.scroller-button-full-height {
  @apply right-0;
}

.scroller-button-right.scroller-button-full-height :deep(.floating-button-content) {
  @apply justify-end;
}

.scroller-button-right-white.scroller-button-full-height {
  @apply bg-gradient-to-l from-white via-white to-white/0;
}

.scroller-button-right-purple-light.scroller-button-full-height {
  @apply bg-gradient-to-l from-purple-light via-purple-light to-purple-light/0;
}
</style>
