<script setup lang="ts">
import { SCROLL_BEHAVIOUR } from '../../types/common';

withDefaults(defineProps<{
  buttonStyleDark?: boolean;
  floatingButtonColor?: string;
  hasFullHeightButtons?: boolean;
  hideArrows?: boolean;
  neverHideButtons?: boolean;
  outsidePadding?: number;
  positionButtonLeft?: string;
  positionButtonRight?: string;
  scrollContentClass?: string;
  scrollDistance?: number;
} &({
  backgroundColor: string;
  fadeOutEdges: true;
} | {
  backgroundColor?: string;
  fadeOutEdges?: false;
})>(), { buttonStyleDark: false,floatingButtonColor: 'white',hasFullHeightButtons: false,hideArrows: false,neverHideButtons: false,outsidePadding: 0,positionButtonLeft: 'left-5',positionButtonRight: 'right-5',scrollDistance: 200, })

const scrollerWrapperElement = ref<HTMLElement>();
const { width: scrollerWrapperWidth } = useElementSize(scrollerWrapperElement);
const isScrollerHovered = useElementHover(scrollerWrapperElement);

const scrollerContentWrapper = ref<HTMLElement>();
const scrollChildrenWidth = ref(0);

const calculateScrollChildrenWidth = () => {
  let contentWidth = 0;
  if (scrollerContentWrapper.value?.children) {
    const childrenArray = htmlCollectionToArray(scrollerContentWrapper.value.children);

    childrenArray.forEach((child) => {
      contentWidth += child.clientWidth;
    });
  }
  scrollChildrenWidth.value = contentWidth + __props.outsidePadding;
};

// There should be at least 2 px difference to show the scroller arrows
const isScrollable = computed(() => scrollChildrenWidth.value > scrollerWrapperWidth.value + 1);

const {
  arrivedState,
  measure,
  x,
} = useScroll(scrollerContentWrapper, { behavior: SCROLL_BEHAVIOUR });

const {
  left,
  right,
} = toRefs(arrivedState);

useMutationObserver(scrollerContentWrapper, () => {
  calculateScrollChildrenWidth();
  measure();
}, {
  childList: true,
  subtree: true,
});

useResizeObserver(scrollerContentWrapper, () => {
  calculateScrollChildrenWidth();
  measure();
});

const { isDesktop } = useDevice();

const scroll = (scrollDistanceParam: number) => {
  x.value += scrollDistanceParam;
};

const isComponentMounted = ref(false);

const showArrows = computed(() => isComponentMounted.value
  && ((__props.neverHideButtons && isDesktop) || (isScrollable.value && isDesktop
  && ((isScrollerHovered.value && !__props.hasFullHeightButtons) || __props.hasFullHeightButtons))
  || (!isDesktop && __props.fadeOutEdges && __props.hideArrows)
  ));

onMounted(() => {
  isComponentMounted.value = true;
});

onBeforeMount(async () => {
  calculateScrollChildrenWidth();
  await nextTick();
  // Set the initial scroll position to start from the left side
  x.value = 0;
});
</script>

<template>
  <div
    ref="scrollerWrapperElement"
    class="ui-scroller group/scroller relative flex w-full overflow-hidden"
  >
    <div
      ref="scrollerContentWrapper"
      :class="cn('ui-scroller-content no-scrollbar flex snap-x snap-mandatory overflow-scroll', scrollContentClass)"
    >
      <slot name="scrollerContent" />
    </div>

    <UiScrollerButtons
      v-if="showArrows"
      :button-style-dark="buttonStyleDark"
      :floating-button-color="floatingButtonColor"
      :has-full-height-buttons="hasFullHeightButtons"
      :hide-arrows="hideArrows"
      :left="left"
      :never-hide-buttons="neverHideButtons"
      :right="right"
      :scroll-distance="scrollDistance"
      :scroller-wrapper-width="scrollerWrapperWidth"
      :styling-button-left="positionButtonLeft"
      :styling-button-right="positionButtonRight"
      @scroll-now="scroll"
    />
  </div>
</template>
